<template>
	<div :style="authBg">
		<Header :title="$t('setting.information')"></Header>
		
		<div class="column-center-content main-content">
			<div class="form-content">
				<label class="form-title">{{$t('setting.name')}}</label>
				<v-text-field :placeholder="$t('setting.name_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="name"></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('setting.idcard')}}</label>
				<v-text-field :placeholder="$t('setting.idcard_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="idcard"></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('setting.birth')}}</label>
				<v-text-field :placeholder="$t('setting.birth_hint')" color="success" @click="showPicker" style="width: 100%; padding-top: 5px;" v-model="birthday" readonly></v-text-field>
				<mt-datetime-picker
					ref="birthdayPicker"
					v-model="pickerVisible"
					type="date"
					:startDate="startDate" 
					:endDate="endDate"
					year-format="{value}"
					month-format="{value}"
					date-format="{value}"
					@confirm="handleChange">
				</mt-datetime-picker>
			</div>
			
			<div class="form-content" style="margin-bottom: 10px;">
				<div class="row-content">
					<label class="form-title">{{$t('setting.idcardImg')}}</label>
					<mt-button @click="showIdCardTip" type="default" style="height: 28px; padding: 0px 8px; margin-left: 10px;"> 
						<i class="fa fa-info-circle" aria-hidden="true" style="color: #009245; font-size: 16px;"></i>
					</mt-button>
				</div>
				<div style="width: 100%; padding-top: 5px;">
					<input type="file" @change="chooseCardImg($event)" accept="image/*" name="cardImgUrls" value="cardImg" style="margin-top: 10px;">
				</div>
			</div>
			
			<div class="form-content">
				<div class="row-content">
					<label class="form-title">{{$t('setting.selfieImg')}}</label>
					<mt-button @click="showSelfieTip" type="default" style="height: 28px; padding: 0px 8px; margin-left: 10px;"> 
						<i class="fa fa-info-circle" aria-hidden="true" style="color: #009245; font-size: 16px;"></i>
					</mt-button>
				</div>
				<div style="width: 100%; padding-top: 5px;">
					<input type="file" @change="chooseSelfieImg" accept="image/*" name="selfieImgUrls" value="selfieImg" style="margin-top: 10px;">
				</div>
			</div>
			
			<div class="column-center-content" style="width: 100%; margin-top: 20px; align-items: flex-start; padding: 10px 20px;">
				<label style="color: #888888; font-size: 18px;">{{$t('setting.auth_tip1')}}</label>
				<label style="color: #888888; font-size: 18px;">{{$t('setting.auth_tip2')}}</label>
				<label style="color: #888888; font-size: 18px;">{{$t('setting.auth_tip3')}}</label>
			</div>
			
			<div class="btn-content">
				<v-btn @click="requestToAuth" rounded block style="background-color: #009245; color: white; height: 40px;">
					{{$t('common.submit')}}
				</v-btn>
			</div>
		</div>
		
		<mt-popup style="width: 90%; height: 90%; align-items: center; border-radius: 10px;" v-model="idCardTipVisible" popup-transition="popup-fade">
			<IdCardTip></IdCardTip>
		</mt-popup>
		
		<mt-popup style="width: 90%; height: 90%; align-items: center; border-radius: 10px;" v-model="selfieImgTipVisible" popup-transition="popup-fade">
			<SelfieImgTip></SelfieImgTip>
		</mt-popup>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import moment from "moment";
	import IdCardTip from '../../../components/IdCardTip.vue'
	import SelfieImgTip from '../../../components/SelfieImgTip.vue'
	import Header from '../../../components/NavHeader.vue'
	import { Toast } from 'mint-ui';
	
	export default {
		name: 'Auth',
		components: {
			IdCardTip,
			SelfieImgTip,
			Header
		},
		data() { 
			return {
				authBg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				name: '',
				idcard: '',
				cardImgUrl: '',
				selfieImgUrl: '',
				birthday: '',
				pickerVisible: false,
				startDate: new Date("1901-01-01"),
				endDate: new Date(),
				idCardTipVisible: false,
				selfieImgTipVisible: false
			}
		},
		methods: {
			showPicker() {
				this.$refs["birthdayPicker"].open();
			},
			showIdCardTip() {
				this.idCardTipVisible = true;
			},
			closeIdCardPop() {
				this.idCardTipVisible = false;
			},
			showSelfieTip() {
				this.selfieImgTipVisible = true;
			},
			closeSelfiePop() {
				this.selfieImgTipVisible = false;
			},
			handleChange(value) {
				this.$data.birthday = moment(value).format('YYYY-MM-DD');
			},
			chooseCardImg(event) {
				this.uploadImage(event.target.files[0], 0);
			},
			chooseSelfieImg() {
				this.uploadImage(event.target.files[0], 1);
			},
			
			uploadImage(file, type) {
				let params = new FormData();
				params.append('file', file);
				this.$request.upload(
					params
				).then((url) => {
					if(type == 0) {
						// 身份证照片
						this.$data.cardImgUrl = url;
					}else{
						// 身份证+自拍照
						this.$data.selfieImgUrl = url;
					}
				});
			},
			
			requestToAuth() {
				let name = this.$data.name;
				let idcard = this.$data.idcard;
				let birthday = this.$data.birthday;
				let cardImgUrl = this.$data.cardImgUrl;
				let selfieImgUrl = this.$data.selfieImgUrl;
				
				if(null == name || '' == name) {
					Toast({
						message: this.$i18n.t("setting.name_hint"),
						position: 'bottom'
					});
					return;
				}
				if(null == idcard || '' == idcard) {
					Toast({
						message: this.$i18n.t("setting.idcard_hint"),
						position: 'bottom'
					});
					return;
				}
				if(null == birthday || '' == birthday) {
					Toast({
						message: this.$i18n.t("setting.birth_hint"),
						position: 'bottom'
					});
					return;
				}
				if(null == cardImgUrl || '' == cardImgUrl) {
					Toast({
						message: this.$i18n.t("setting.idcardImg"),
						position: 'bottom'
					});
					return;
				}
				if(null == selfieImgUrl || '' == selfieImgUrl) {
					Toast({
						message: this.$i18n.t("setting.selfieImg"),
						position: 'bottom'
					});
					return;
				}
				
				let params = {
					name: name,
					cardId: idcard,
					birth: birthday,
					imgId: cardImgUrl,
					imgAvatar: selfieImgUrl
				}
				
				this.$request.submitKyc(
					params
				).then(() => {
					//跳转首页
					this.$router.push({
						name: 'Main'
					})
				});
			}
		}
	}
</script>

<style>
</style>
