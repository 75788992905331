<template>
	<div :style="bg">
		<div class="row-content" style="width: 100%; justify-content: flex-end; margin-right: 30px;">
			<button @click="closePop" type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true" style="font-size: 32px;">×</span>
			</button>
		</div>
		
		<label style="margin-top: 35px; color: #FF0000; font-weight: bold; font-size: 24px;">{{$t('tips.uploadSelfie')}}</label>
		<label style="color: #888888; font-size: 14px;">{{$t('tips.idcarTip1')}}</label>
		<label style="color: #888888; font-size: 14px;">{{$t('tips.idcarTip2')}}</label>
		
		<img src="../assets/setting/selfie-good.jpg" />
		<label style="color: #888888; font-size: 14px; font-weight: bold;">{{$t('tips.goodSample')}}</label>
		
		<div style="display: flex; flex-direction: row; margin-top: 20px;">
			<div class="column-center-content" style="width: 60%;">
				<img style="width: 80%;" src="../assets/setting/selfie-cover.jpg" />
				<label style="color: #888888; font-size: 14px; font-weight: bold;">{{$t('tips.badSample1')}}</label>
			</div>
			<div class="column-center-content" style="width: 60%;">
				<img style="width: 80%;" src="../assets/setting/selfie-shine.jpg" />
				<label style="color: #888888; font-size: 14px; font-weight: bold;">{{$t('tips.badSample2')}}</label>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	
	export default {
		name: 'IdCardTip',
		data() { 
			return {
				bg : {
					height: "100%",
					width: "100%",
					backgroundImage: "url(" + require("../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto",
					borderRadius: "10px"
				}
			}
		},
		methods: {
			closePop() {
				this.$parent.$parent.closeSelfiePop();
			}
		}
	}
</script>

<style>
	
</style>
